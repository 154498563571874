import React, { useEffect } from "react";

import { MicrosoftLogo } from "@/app/assets/components/MicrosoftLogo";
import { useToast } from "@/app/core/display/toast/store/useToast";
import { useQueryValue } from "@/app/core/http/hooks/useQueryValue";
import { routes } from "@/app/core/http/routes";
import { Box } from "@/app/core/ui/components/Box";
import { Flex } from "@/app/core/ui/components/Flex";
import { Loader } from "@/app/core/ui/components/Loader";
import { OblidoLogo } from "@/app/core/ui/components/OblidoLogo";
import { Text } from "@/app/core/ui/components/Text";

interface LoginFormProps {
  isRedirecting: boolean;
}

export const LoginForm: React.FC<LoginFormProps> = ({ isRedirecting }) => {
  const error = useQueryValue("error");
  const next = useQueryValue("next");

  useEffect(() => {
    if (error) {
      useToast.getState().showToast({
        message: error,
        type: "error",
      });
    }
  }, [error]);

  return (
    <Flex center tw="fixed top-0 left-0 w-full h-full flex-col bg-gray-100">
      <Flex
        center
        tw="flex-col max-w-[450px] w-full px-4 py-5 border border-gray-100 rounded-xl bg-white border border-gray-200"
      >
        <OblidoLogo tw="mb-4" width={40} icon />

        <Flex tw="flex-col w-full">
          <Text size="xl" weight="bold" tw="mb-1">
            Inicia sesión
          </Text>
          <Text color="gray-700">
            Accede a tu cuenta de Oblido iniciando sesión con tu cuenta de Microsoft.
          </Text>
        </Flex>

        <Box tw="mt-4">
          {isRedirecting ? (
            <Loader centered tw="h-5" />
          ) : (
            <button
              tw="flex items-center bg-gray-150 px-2 py-1 gap-1.5 border border-gray-200 rounded-md hover:bg-gray-200"
              onClick={() => location.replace(routes.api.logIn(next))}
            >
              <MicrosoftLogo width={27} />
              <Text weight="medium">Accede con Microsoft</Text>
            </button>
          )}
        </Box>
      </Flex>
    </Flex>
  );
};
