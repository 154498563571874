import { useEffect, useState } from "react";

import { useRouter } from "next/router";
import type { z } from "zod";

export function useQueryValue(key: string): string | undefined;
export function useQueryValue<T extends z.ZodTypeAny>(
  key: string,
  schema: T
): z.infer<T> | undefined;
export function useQueryValue(key: string, schema?: z.ZodTypeAny): string | undefined {
  const [value, setValue] = useState<string>();
  const { query } = useRouter();

  useEffect(() => {
    const v = query[key];

    if (v && typeof v === "string") {
      let parsedValue = decodeURIComponent(v);

      if (schema) {
        const result = schema.safeParse(parsedValue);
        if (!result.success) {
          setValue(undefined);
          return;
        }

        parsedValue = result.data;
      }

      setValue(decodeURIComponent(parsedValue));
    } else {
      setValue(undefined);
    }
  }, [key, query, schema]);

  return value;
}
