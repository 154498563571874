import React, { useEffect, useState } from "react";

import { useRouter } from "next/router";

import { useAuth } from "@/app/auth/hooks/useAuth";
import { LoginForm } from "@/app/auth/login/LoginForm";
import { useQueryValue } from "@/app/core/http/hooks/useQueryValue";
import type { PageComponent } from "@/app/core/types/tsx";

const LoginPage: PageComponent = () => {
  const [isRedirecting, setIsRedirecting] = useState(false);

  const next = useQueryValue("next");
  const { push } = useRouter();

  const { isAuthed } = useAuth();

  useEffect(() => {
    let id: NodeJS.Timeout | null = null;

    if (isAuthed) {
      setIsRedirecting(true);
      id = setTimeout(() => {
        location.replace(next || "/");
      }, 1000);
    }

    return () => (id ? clearTimeout(id) : undefined);
  }, [isAuthed, next, push]);

  return <LoginForm isRedirecting={isRedirecting} />;
};

LoginPage.skipAuth = true;

export default LoginPage;
